
.create-content{
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    .create-content-top{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .create-content-left{
            /*::v-deep.el-button--primary.is-plain {*/
            /*    color: #1CB064;*/
            /*    background: transparent;*/
            /*    border-color: #a4dfc1;*/
            /*}*/
        }
        .create-content-right{

            display: flex;
            align-items: center;
            .label-name{
                min-width: 100px;
            }
        }
        .name-input {
            width: 325px;
            ::v-deep .el-input__inner {
                border-radius: 0;
            }
        }
    }
    .success-content{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 140px;
        .text{
            font-size: 16px;
            color: #333;
            font-weight: bold;
        }
    }
}
.create-table {
    display: flex;
    flex-direction: column;
}
.bottom-btn{
    text-align: center;
    padding: 20px;
}
.customTable {
    .text {
        flex: 1;
        width: 1%;
        text-align: left;
    }
    .one-click-creativity {
        width: 74px;
        height: 24px;
        background-color: #2D98F4;
        color: #fff;
        font-size: 14px;
        line-height: 24px;
        margin-left: 20px;
    }
    ::v-deep {
        .el-table__row {
            &:nth-child(16), &:nth-child(17) {
                td:first-child > .cell {
                    /*display: flex;*/
                    img.icon {
                        display: block;
                    }
                }
            }
            .ad-img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 150px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        td:first-child > .cell {
            /*display: flex;*/
            img.icon {
                display: none;
            }
        }
    }
}
::v-deep .input-error {
    &.isError {
        .el-input__inner {
            border-color: #FD4446;
        }
    }
}
